import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Figure from "react-bootstrap/Figure";
import ListGroup from "react-bootstrap/ListGroup";
import Badge from "react-bootstrap/Badge";
import addLineBreaks from "../utils/addLineBreaks";
import productUnitPriceDisplay from "../utils/productPriceDisplay";

export default function ProductsList(props) {
  const { products, onProductSelect = () => {} } = props;
  if (!products) {
    return (
      <div className="d-flex flex-column text-center my-5">
        <p>Ops, nenhum produto encontrado...</p>
      </div>
    );
  }

  return (
    <>
      <ListGroup>
        {products.map((product) => (
          <ListGroup.Item
            className="borderless"
            key={product.id}
            onClick={() => onProductSelect(product.id)}
          >
            <Row>
              <Col xs={product.pictureUrl ? 8 : 12}>
                <ProductName product={product} />
                <ProductBadges product={product} />
                <ProductDetails product={product} />
                <ProductPrice product={product} />
              </Col>
              {product.pictureUrl && (
                <Col
                  xs="4"
                  className="d-flex flex-column justify-content-center"
                >
                  <Figure.Image
                    loading="lazy"
                    src={product.pictureUrl}
                    alt="Ilustração do produto"
                    fluid={false}
                    className="w-100"
                    rounded
                  />
                </Col>
              )}
            </Row>
          </ListGroup.Item>
        ))}
      </ListGroup>
    </>
  );
}

export function ProductName({ product }) {
  return (
    <p className="mb-1">
      <small className="font-weight-bold">{product.name}</small>
    </p>
  );
}

export function ProductBadges({ product }) {
  if (!product.disabled) {
    return null;
  }

  return (
    <Badge className="mb-3" variant="secondary">
      <small>INDISPONÍVEL</small>
    </Badge>
  );
}

export function ProductDetails({ product }) {
  if (!product.details) {
    return null;
  }

  return (
    <p className="m-0">
      <small className="text-muted products-details">
        {addLineBreaks(product.details)}
      </small>
    </p>
  );
}

export function ProductPrice({ product }) {
  return (
    <p className="mt-3 mb-0">
      <small className="font-weight-bold">
        {productUnitPriceDisplay(product)}
      </small>
    </p>
  );
}
