import { useEffect } from "react";

const useShopStyle = (shop) => {
  const backgroudColor = shop?.backgroudColor || "#dc3545";
  const textColor = shop?.textColor || "#fff";

  useEffect(() => {
    if (!backgroudColor || !textColor) return;
    const css = document.createElement("style");
    css.type = "text/css";
    css.async = false;
    css.innerHTML = `
      .btn-shop {
        border-color: ${backgroudColor};
        background-color: ${backgroudColor};
        color: ${textColor};
      }

      .btn.btn-link,
      .product-price-color-shop {
        color: ${backgroudColor};
      }

      .border-color-shop {
        border-color: ${backgroudColor};
      }

    `;
    document.body.appendChild(css);
  }, [backgroudColor, textColor]);
};

export default useShopStyle;
